@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  background-color: #ffffff;
  font-family: 'Nunito Sans', sans-serif;
}

.Online-form {
  max-width: 510px;
  height: 55px;
  padding: 12px 20px;
  border: none;
  outline: none;
  background: #ffffff;
  border-radius: 5px;
}

.Physical-form {
  max-width: 315px;
  height: 55px;
  padding: 12px 20px;
  border: none;
  outline: none;
  background: #ffffff;
  border-radius: 5px;
}

.Profile-form {
  max-width: 580px;
  height: 50px;
  border: none;
  outline: none;
  margin-top: 5px;
  padding: 12px 20px;
  background: #fafbfc;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

.Examineers-Info {
  max-width: 225px;
  height: 35px;
  padding: 12px 10px;
  color: #4d5765;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  background: #fafbfc;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  outline: none;
}

.Message {
  max-width: 100%;
  max-width: 527px;
  height: 120px;
  padding: 15px 20px;
  box-sizing: border-box;
  border: 1px solid #ffffff;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 16px;
  resize: none;
  outline: none;
}

.Nav {
  list-style: none;
  cursor: pointer;
}

ul.Nav li {
  color: #808080;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}

ul.Nav li.active {
  height: 32px;
  color: #0d5ef4;
  border-bottom: 3px solid #0d5ef4;
}

.login-form {
  height: 55px;
  background: #ffff;
  border: none;
  outline: none;
  width: 100%;
  /* border: 1px solid red; */
  padding: 15px 20px;
  border-radius: 5px;
  margin-top: 15px;
  @media (max-width: 400px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.Contact-form {
  padding: 15px;
  border: none;
  min-height: 45px;
  max-width: 251px;
  width: 100%;
  outline: none;
  border-radius: 5px;
}
.btnActive {
  color: #0d5ef4;
}

.swiper-slide {
  position: relative;
  width: 100%;
}
